.producing {
    font-size: x-small;
    color: var(--main-bg-color);
    font-family: "Quicksand", sans-serif !important;
    padding-top: 10px;
}
.toggle-button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none !important;
    cursor: pointer;
    overflow: hidden;
    outline: none !important;
}
.how-to-configure {
    width: 500px !important;
}
