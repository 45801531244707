@import url(https://fonts.googleapis.com/css?family=Quicksand&display=swap);
.background-color {
    background-color: #2e2f36 !important;
}

.background-color-alter {
    background-color: red !important;
}

.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}
.content-wrap {
    padding-bottom: 3.5rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.producing {
    font-size: x-small;
    color: var(--main-bg-color);
    font-family: "Quicksand", sans-serif !important;
    padding-top: 10px;
}
.toggle-button {
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none !important;
    cursor: pointer;
    overflow: hidden;
    outline: none !important;
}
.how-to-configure {
    width: 500px !important;
}

:root {
    --main-bg-color: whitesmoke;
    --secondary-txt-color: rgb(194, 194, 194);
    --acent-color: #86e0ce;
}

.introView {
    padding: 40px !important;
}

.textDetails {
    color: whitesmoke;
    color: var(--main-bg-color);
    font-family: "Quicksand", sans-serif !important;
    width: 500px;
    font-size: 15px;
}

.titleText {
    color: whitesmoke !important;
    color: var(--main-bg-color) !important;
    font-size: 40px !important;
    font-family: "Quicksand", sans-serif !important;
}

.subTitleText {
    color: rgb(194, 194, 194) !important;
    color: var(--secondary-txt-color) !important;
    font-size: 20px !important;
    font-family: "Quicksand", sans-serif !important;
    width: 400px;
    text-align: center;
    margin: 0 !important;
}

.startButtonBorder {
    border: 2px solid #86e0ce !important;
    border: 2px solid var(--acent-color) !important;
    border-radius: 12px !important;
}

.startButton {
    color: #86e0ce !important;
    color: var(--acent-color) !important;
    width: 30% !important;
    font-size: 30px !important;
    margin-top: 15px;
}

.startButton:hover {
    background-color: #86e0ce !important;
    color: white !important;
}
.title-custom-padding {
    padding-top: 20px;
    margin: 0;
}
.subtitle-custom-padding {
    padding-bottom: 20px;
}
.link {
    text-decoration: none !important;
    color: #86e0ce;
    color: var(--acent-color);
}

@media (max-width: 576px) {
    .introView {
        padding: 5% !important;
    }
    .titleText {
        color: whitesmoke !important;
        color: var(--main-bg-color) !important;
        font-size: 27px !important;
        font-family: "Quicksand", sans-serif !important;
    }
    .subTitleText {
        color: rgb(194, 194, 194) !important;
        color: var(--secondary-txt-color) !important;
        font-size: 18px !important;
        font-family: "Quicksand", sans-serif !important;
        width: 400px;
        text-align: center;
        margin: 0 !important;
    }

    .startButton {
        width: 100% !important;
        font-size: 27px !important;
    }

    .textDetails {
        color: whitesmoke;
        color: var(--main-bg-color);
        font-family: "Quicksand", sans-serif !important;

        font-size: 13px;
    }
}

@media (min-width: 577px) and (max-width: 990px) {
    .startButton {
        width: 50% !important;
        font-size: 27px !important;
    }
}

a {
    color: inherit;
}
.item1 {
    border: none;
    background-color: inherit;
    color: #94dfb4;
    padding-left: 10px;
    font-size: 20px;
    display: flex;
    justify-content: left;
    align-items: center;
}
.item1:hover {
    text-decoration: none;
    color: #48976a;
}
.item1:focus {
    outline: 0;
}
.item2 {
    background: rgba(49, 49, 54, 0.993);
    color: rgb(190, 190, 190);
    margin: 1px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item3 {
    font-size: 20px;
    text-decoration: none;
    background: #292a30;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item3:hover {
    color: inherit;
    background-color: #000000;
    transition: 0.4s;
}
.item4 {
    font-size: 20px;
    background: #25252b;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item4:hover {
    color: inherit;
    background-color: #4267b2;
    transition: 0.4s;
}
.item5 {
    font-size: 20px;
    background: #202025;
    display: flex;
    justify-content: center;
    align-items: center;
}
.item5:hover {
    color: inherit;
    background-color: #b91818;
    transition: 0.4s;
}

.footer-container {
    color: aliceblue;
    text-align: center;
    background-color: rgba(49, 49, 54, 0.993);
    font-size: 15px;
    width: 100%;
    height: 3.5rem;
    display: grid;
    grid-template-columns: 3fr 6fr 1fr 1fr 1fr;
}

/* Extra small window for eye on you 300px and down*/
@media (max-width: 300px) {
    .item1 {
        font-size: 7px;
        padding-left: 1px;
    }
    .item2 {
        font-size: 5px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 8px;
    }
    .footer-container {
        height: 1.5rem;
    }
}

/* Extra small devices (phones, 300px and up) */
@media only screen and (min-width: 300px) {
    .item1 {
        font-size: 12px;
        padding-left: 3px;
    }
    .item2 {
        font-size: 7px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 7px;
    }
    .footer-container {
        height: 2rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .item1 {
        font-size: 14px;
        padding-left: 5px;
    }
    .item2 {
        font-size: 9px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 10px;
    }
    .footer-container {
        height: 2rem;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .item1 {
        font-size: 16px;
        padding-left: 6px;
    }
    .item2 {
        font-size: 11px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 16px;
    }
    .footer-container {
        height: 3rem;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .item1 {
        font-size: 18px;
        padding-left: 8px;
    }
    .item2 {
        font-size: 13px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 18px;
    }
    .footer-container {
        height: 3.5rem;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .item1 {
        font-size: 20px;
        padding-left: 10px;
    }
    .item2 {
        font-size: 15px;
    }
    .item3,
    .item4,
    .item5 {
        font-size: 20px;
    }
    .footer-container {
        height: 3.5rem;
    }
}

.how-it-works {
    border: none;
    background-color: inherit;
    color: #94dfb4;
    display: flex;
    justify-content: center;
    align-items: center;
}
.how-it-works:hover {
    text-decoration: none;
    color: #48976a;
}
.how-it-works:focus {
    outline: 0;
}


