.background-color {
    background-color: #2e2f36 !important;
}

.background-color-alter {
    background-color: red !important;
}

.App {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
}
.content-wrap {
    padding-bottom: 3.5rem;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}
